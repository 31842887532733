import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Renderer2
} from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { EventAggregator } from "@vp/data-access/application";
import {
  JwtPayload,
  PaymentPayload,
  PaymentTransactionResult,
  TransactionStatusEvent
} from "@vp/models";
import { CaseContextService } from "@vp/shared/case-context";
import { CybersourceService } from "@vp/shared/cybersource-service";
import { FeatureService } from "@vp/shared/features";
import { FeeService } from "@vp/shared/fee-service";
import { NotificationService } from "@vp/shared/notification-service";
import { filterNullMap } from "@vp/shared/operators";
import { jwtDecode } from "jwt-decode";
import { BehaviorSubject, EMPTY, Observable, Subject, timer } from "rxjs";
import { finalize, switchMap, takeUntil } from "rxjs/operators";
declare const Flex: any;

@Component({
  selector: "vp-cybersource-payment",
  templateUrl: "./cybersource-payment.component.html",
  styleUrls: ["./cybersource-payment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CybersourcePaymentComponent
  extends FieldType
  implements OnInit, AfterViewInit, OnDestroy
{
  microform: any;

  fee$ = new BehaviorSubject<number>(0);
  yearList: number[] = [];
  isPaid$ = new BehaviorSubject<boolean>(false);
  isLoading$ = new BehaviorSubject<boolean>(true);
  cybersourceAdditionalMsg$ = new Observable<string>();
  private disabledSubject = new BehaviorSubject<boolean>(true);
  private readonly destroyed$ = new Subject<void>();
  private caseId = "";

  constructor(
    private feeService: FeeService,
    private featureService: FeatureService,
    private caseContextService: CaseContextService,
    private cybersouceService: CybersourceService,
    private notificationService: NotificationService,
    private eventAggregator: EventAggregator,
    private renderer: Renderer2
  ) {
    super();
    this.populateYearList();
    this.cybersourceAdditionalMsg$ = this.featureService.configurationValue$(
      "caseDashboard",
      "cybersourceAdditionalMsg"
    );
  }

  disabled$ = this.disabledSubject.asObservable();

  ngOnInit(): void {
    this.caseContextService.contextRecordDataValidation
      .pipe(filterNullMap(), takeUntil(this.destroyed$))
      .subscribe(isValid => {
        this.disabledSubject.next(!isValid);
      });

    this.isLoading$.next(true);
    this.caseContextService.caseData$
      .pipe(filterNullMap(), takeUntil(this.destroyed$))
      .subscribe(caseData => {
        this.caseId = caseData.caseId;
        this.isPaid$.next(caseData.paid);
      });
    this.feeService.totalFees$.subscribe(fee => this.fee$.next(fee));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.fee$
      .pipe(
        switchMap(fee => {
          if (fee <= 0 || this.isPaid$.getValue()) {
            this.isPaid$.next(true);
            return EMPTY;
          } else {
            this.isPaid$.next(false);
            // token expires after 15 min, refresh token every 10 min
            return timer(1, 600000).pipe(
              switchMap(_ => this.cybersouceService.getTransientToken())
            );
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(tokenResponse => this.loadMicroform(tokenResponse));
  }

  private async loadMicroform(tokenResponse: any) {
    try {
      const decoded = jwtDecode(tokenResponse.data) as JwtPayload;
      const contextData = decoded.ctx[0].data;
      const clientLibrary = contextData.clientLibrary;
      const clientLibraryIntegrity = contextData.clientLibraryIntegrity;

      //Load the Microform library dynamically
      this.loadLibrary(clientLibrary, clientLibraryIntegrity)
        .then(() => {
          const style = {
            input: {
              "font-size": "16px",
              color: "#3A3A3A"
            },
            ":disabled": {
              cursor: "not-allowed"
            },
            valid: {
              color: "green"
            },
            invalid: {
              color: "red"
            }
          };
          const flex = new Flex(tokenResponse.data);
          this.microform = flex.microform({ styles: style });
          const cardNumber = this.microform.createField("number", {
            disabled: this.disabledSubject.getValue(),
            placeholder: "Enter card number",
            styles: { disabled: { cursor: "not-allowed" } }
          });
          const securityCode = this.microform.createField("securityCode", {
            disabled: this.disabledSubject.getValue(),
            placeholder: "***",
            styles: { disabled: { cursor: "not-allowed" } }
          });

          cardNumber.on("change", function (data: any) {
            const container = document.querySelector<HTMLDivElement>("#number-container");
            const brandedCardImg = container?.querySelector<HTMLImageElement>("#branded-card-img");

            if ((data.card.length === 0 || data.card.length > 1) && container && brandedCardImg) {
              container.removeChild(brandedCardImg);
            } else if (data.card.length === 1 && container) {
              const cardBrandedName = data.card[0].brandedName;
              const cardImage = document.createElement("img");
              cardImage.id = "branded-card-img";

              switch (cardBrandedName) {
                case "Visa":
                  if (brandedCardImg) {
                    brandedCardImg.className = "visa-card-img";
                    brandedCardImg.src = getImagePath(cardBrandedName);
                    break;
                  }
                  cardImage.className = "visa-card-img";
                  cardImage.src = getImagePath(cardBrandedName);
                  container.appendChild(cardImage);
                  break;
                case "MasterCard":
                  if (brandedCardImg) {
                    brandedCardImg.className = "mastercard-img";
                    brandedCardImg.src = getImagePath(cardBrandedName);
                    break;
                  }
                  cardImage.className = "mastercard-img";
                  cardImage.src = getImagePath(cardBrandedName);
                  container.appendChild(cardImage);
                  break;
                case "Discover":
                  if (brandedCardImg) {
                    brandedCardImg.className = "discover-card-img";
                    brandedCardImg.src = getImagePath(cardBrandedName);
                    break;
                  }
                  cardImage.className = "discover-card-img";
                  cardImage.src = getImagePath(cardBrandedName);
                  container.appendChild(cardImage);
                  break;
                default:
                  if (brandedCardImg) {
                    container.removeChild(brandedCardImg);
                  }
                  break;
              }
            }
          });

          cardNumber.load("#number-container");
          securityCode.load("#securityCode-container");
          this.isLoading$.next(false);
        })
        .catch(error => {
          console.error("Failed to load Microform", error);
        });
    } catch (error) {
      console.error("Failed to decode JWT", error);
    }
  }

  /*Load Library dynamically to adhere to PCI DSS 4.0.1 requirements*/
  loadLibrary(clientLibrary: string, clientLibraryIntegrity: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement("script");
      script.src = clientLibrary;
      script.integrity = clientLibraryIntegrity;
      script.crossOrigin = "anonymous";

      script.onload = () => resolve();
      script.onerror = (error: any) => reject(error);

      document.body.appendChild(script);
    });
  }

  onSubmit(form: any) {
    this.isLoading$.next(true);
    const options = {
      expirationMonth: form.value.expMonth,
      expirationYear: form.value.expYear.toString()
    };

    this.microform.createToken(options, (err: any, token: any) => {
      if (err) {
        switch (err.reason) {
          case "CREATE_TOKEN_VALIDATION_FIELDS":
            this.notificationService.errorMessage("Invalid Card");
            break;
          default:
            this.notificationService.errorMessage("Payment error, please try again");
            break;
        }
        this.isLoading$.next(false);
      } else {
        const payload = {
          totalAmount: this.fee$.getValue().toFixed(2),
          currency: "USD",
          clientReferenceInformation: `caseId:${this.caseId}`,
          transientToken: token
        } as PaymentPayload;
        this.cybersouceService
          .submitPayment(this.caseId, payload, true)
          .pipe(
            finalize(() => {
              this.isLoading$.next(false);
            })
          )
          .subscribe((transactionResult: PaymentTransactionResult) => {
            this.eventAggregator.emit(
              new TransactionStatusEvent(transactionResult),
              "cybersource.submit"
            );
            if (transactionResult.status === "success") {
              this.isPaid$.next(true);
              this.notificationService.successMessage("Payment success");
            } else {
              this.notificationService.errorMessage("Payment failed");
            }
            return EMPTY;
          });
      }
    });
  }

  private populateYearList() {
    const year = new Date().getFullYear();
    for (let index = 0; index < 15; index++) {
      this.yearList.push(year + index);
    }
  }
}

function getImagePath(imageName: string): string {
  const basePath = "assets/cybersource/";
  const extension = "_logo.png";
  return `${basePath}${imageName}${extension}`;
}
